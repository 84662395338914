body {
    overflow-x: hidden;
}
.page-careers * {
    z-index: 1;
}
.page-careers .animated {
    position: fixed;
    left: 50%;
    top: 0;
    transform: translate(-65%,-85%) rotate(140deg);
    width: auto;
    max-width: 150%;
    z-index: 0;
    transition: 1s;
    opacity: 0;
}
.page-careers .animated._active {
    transform: translate(-65%,-85%) rotate(180deg);
    transition: 0s;
    opacity: 1;
}
.page-careers .first-screen {
    overflow: hidden;
}
.page-careers .first-screen__container {
    position: relative;
    transition: 1s;
    top: 1000px;
}
.page-careers .first-screen__container._active {
    top: 0;
}
@media (max-width: 767px) {
    .page-careers .animated {
        max-width: 350%;
    }
}
.page-our-focus * {
    z-index: 4;
}
.page-our-focus .animated-1 {
    position: fixed;
    right: -3vw;
    bottom: -6vh;
    top: auto;
    transform: rotate(120deg);
    opacity: 0;
    width: 56vw;
    height: auto;
    z-index: 3;
    transition: 1s;
}
.page-our-focus .animated-2 {
    position: fixed;
    right: 6vw;
    bottom: 8vh;
    top: auto;
    transform: rotate(240deg);
    width: 36vw;
    padding: 20px;
    height: auto;
    z-index: 0;
}
.page-our-focus .animated-2 .img {
    width: calc(100% - 40px);
    height: auto;
    position: relative;
    z-index: 1;
    opacity: 0;
    transform: rotate(180deg) scale(1);
    transition: 1s;
}
.page-our-focus .animated-1._active,
.page-our-focus .animated-2 .img._active {
    opacity: 1;
    transition: 0s;
}
.page-our-focus .animated-2:before {
    content: "";
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-55deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 70%);
}
.page-our-focus .screen__container {
    position: relative;
    z-index: 5;
    overflow: hidden;
}
.page-our-focus .screen__title {
    position: relative;
    top: 1000px;
    transition: 1s;
}
.page-our-focus .portfolio {
    position: relative;
    z-index: 4;
    background: #000000;
}
.page-our-focus .screen__title._active {
    top: 0;
}
@media (max-width: 1440px) {
    .page-our-focus .animated-2:before {
        display: none;
    }
    .page-our-focus .animated-1 {
        width: 70vh;
        height: 70vh;
        bottom: 15vh;
        top: 15vh;
    }
    .page-our-focus .animated-2 {
        width: 40vh;
        height: 40vh;
        max-width: 25vw;
        bottom: 28vh;
        top: 28vh;
        right: 5vw;
    }
    .page-our-focus .animated-2 img {
        width: auto;
        height: 100%;
        text-align: right;
    }
}
@media (max-width: 1020px) {
    .page-our-focus .animated-2 {
        width: 45vh;
        height: 45vh;
        max-width: 40vw;
        bottom: 28vh;
        top: 25vh;
        right: 7vw;
    }
}
@media (max-width: 767px) {
    .page-our-focus .animated-1 {
        width: 30vh;
        height: 30vh;
        bottom: 35vh;
        top: 35vh;
    }
    .page-our-focus .animated-2 {
        width: 24vh;
        max-width: 40vw;
        height: 24vh;
        bottom: 37vh;
        top: 35vh;
        right: 0vw;
    }

}
@media (max-width: 480px) {
    .page-our-focus .animated-2 {
        max-width: 50vw;
        bottom: 41vh;
        top: 35vh;
        right: 0;
    }
}
.page .first-screen__title {

}
.page .first-screen__title span {
    position: relative;
    top: 50px;
    transition: 0.5s;
    opacity: 0;
}
.page .first-screen__title span._animated {
    opacity: 1;
    top: 0;
}
.page .first-screen__text span {
    position: relative;
    top: 50px;
    transition: 0.5s;
    opacity: 0;
}
.page .first-screen__text span._animated {
    opacity: 1;
    top: 0;
}
.page .page__first-screen {
    overflow: hidden;
}
.page .page__first-screen .animated-1 {
    position: fixed;
    right: 3vw;
    bottom: 0;
    transform: rotate(200deg);
    opacity: 0;
    height: auto;
    z-index: 1;
    left: -50%;
    width: 172%;
    top: -80px;
    transition: 1s;
}
.page .page__first-screen .animated-2 {
    position: fixed;
    right: 3vw;
    bottom: 0;
    transform: rotate(160deg);
    opacity: 0;
    height: auto;
    z-index: 0;
    left: -35%;
    width: 116%;
    top: 240px;
    mix-blend-mode: hard-light;
    transition: 1s;
}
.page .page__first-screen .animated-1._active,
.page .page__first-screen .animated-2._active {
    opacity: 1;
    transition: 0s;
}
.page .page__first-screen .first-screen__link {
    position: relative;
    z-index: 5;
    top: 50px;
    opacity: 0;
    transition: 0.5s;
}
.page .page__first-screen .first-screen__link._animated {
    opacity: 1;
    top: 0;
}
@media (max-width: 1080px) {
    .page .page__first-screen .animated-1 {
        width: 200%;
        left: -70%;
    }
    .page .page__first-screen .animated-2 {
        width: 200%;
    }
}
@media (max-width: 767px) {
    .page .page__first-screen .animated-1 {
        width: 400%;
        left: -180%;
    }
    .page .page__first-screen .animated-2 {
        width: 300%;
    }
}
.page .our,
.page .positions {
    position: relative;
    z-index: 1;
}
.page .staff {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.page .staff .main-staff-animation {
    position: absolute!important;
    left: 50%;
    top: 0;
    transform: translate(-90%,-120%) rotate(0deg);
    opacity: 1;
    width: auto;
    max-width: 150%;
    z-index: 0;
}
@media (max-width: 1440px) {
    .page .staff .main-staff-animation {
        max-width: 250%;
    }
}
@media (max-width: 767px) {
    .page .staff .main-staff-animation {
        max-width: 350%;
    }
}
.page .staff * {
    position: relative;
    z-index: 5;
}
.page-who-we-are .first-screen,
.page-who-we-are .team,
.page-who-we-are .join {
    position: relative;
    z-index: 3;
}
.page-who-we-are .animated-1 {
    position: fixed;
    top: 0;
    transform: translate(-25%, 50%) rotate(100deg);
    height: auto;
    z-index: 2;
    left: 50%;
    width: 180%;
    opacity: 0;
    transition: 1s;
}
.page-who-we-are .animated-2 {
    position: fixed;
    top: 0;
    transform: translate(-20%, -70%) rotate(-190deg);
    height: auto;
    z-index: 2;
    left: 50%;
    width: 130%;
    mix-blend-mode: hard-light;
    opacity: 0;
    transition: 1s;
}
.page-who-we-are .animated-1._active,
.page-who-we-are .animated-2._active {
    opacity: 1;
    transition: 0s;
}
.page-who-we-are  .first-screen {
    overflow: hidden;
}
.page-who-we-are  .first-screen__container {
    position: relative;
    top: 1000px;
    transition: 1s;
}
.page-who-we-are .first-screen__container._active {
    top: 0;
}
@media (max-width: 1440px) {
    .page-who-we-are .animated-1 {
        width: 210%;
    }
    .page-who-we-are .animated-2 {
        width: 180%;
    }
}
@media (max-width: 767px) {
    .page-who-we-are .animated-1 {
        width: 280%;
    }
    .page-who-we-are .animated-2 {
        width: 220%;
    }
}
.footer {
    position: relative;
    z-index: 5;
}
