@charset "UTF-8";


* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}


*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}


html,
body {
  height: 100%;
  min-width: 375px;
}


body {
  color: #000;
  line-height: 1;
  font-family: "GT Ultra Fine", "Gerbera";
  font-size: 0.875rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


input,
button,
textarea {
  font-family: "GT Ultra Fine", "Gerbera";
  font-size: inherit;
}


button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
}


a {
  color: inherit;
}


a:link,
a:visited {
  text-decoration: none;
}


a:hover {
  text-decoration: none;
}


ul li {
  list-style: none;
}


img {
  vertical-align: top;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}


body {
  background: #000;
}


body.lock {
  overflow: hidden;
  -ms-touch-action: none;
      touch-action: none;
}


.wrapper {
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: clip;
}


.wrapper > main {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}


.wrapper > * {
  min-width: 0;
}


/*
(i) Стили будут применяться ко 
всем классам содержащим *__container
Например header__container, main__container и т.д.
Снипет (HTML): cnt
*/


[class*=__container] {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 30px;
}


[class*=-ibg] {
  position: relative;
}


[class*=-ibg] img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
}


[class*=-ibg_contain] img {
  -o-object-fit: contain;
     object-fit: contain;
}


.header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 120px;
  z-index: 9;
}


.header--scroll {
  position: fixed;
  height: 80px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}


.header--black {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}


.header--always-black {
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}


.header--hide {
  opacity: 0;
  pointer-events: none;
}


.header__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1340px;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}


.header--scroll .header__container {
  padding-top: 20px;
  padding-bottom: 20px;
}


.header__menu {
  margin-left: auto;
}


.header--scroll {
  background: #FBF9FA;
}


.logo__img {
  display: block;
  width: 135px;
}


.header--ru .logo__img,
.footer--ru .logo__img,
.page-ru .logo__img {
  width: 146px;
}


.logo__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}


.logo__img img:nth-child(1) {
  display: block;
  height: inherit;
}


.header--black .logo__img img:nth-child(1) {
  display: none;
}


.header--always-black .logo__img img:nth-child(1) {
  display: none;
}


.header._menu-open .logo__img img:nth-child(1) {
  display: block;
}


.logo__img img:nth-child(2) {
  display: none;
}


.header--black .logo__img img:nth-child(2) {
  display: block;
}


.header--always-black .logo__img img:nth-child(2) {
  display: block;
}


.header._menu-open .logo__img img:nth-child(2) {
  display: none;
}


.menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}


.menu__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 40px;
}


.menu__item:not(:last-child) {
  margin-right: 41px;
}


.menu__link {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FBF9FA;
}


.header--ru .menu__link,
.footer--ru .menu__link,
.page-ru .menu__link {
  font-size: 14px;
  line-height: 20px;
  font-family: "Gerbera";
}


.header--black .menu__link {
  color: #000000;
}


.menu__bt {
  background: transparent;
}


.header--black .menu__bt {
  color: #000000;
  border-color: #000000;
}


.header--always-black .menu__bt {
  color: #000000;
  border-color: #000000;
}


.menu__btn-icon {
  display: none;
}


.header._menu-open .menu__btn-icon {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iNC4wMDAxMiIgeT0iMyIgd2lkdGg9IjI0IiBoZWlnaHQ9IjIiIHJ4PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSg0NSA0LjAwMDEyIDMpIiBmaWxsPSIjRkJGOUZBIi8+CjxyZWN0IHg9IjMiIHk9IjE5Ljk5OTkiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyIiByeD0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoLTQ1IDMgMTkuOTk5OSkiIGZpbGw9IiNGQkY5RkEiLz4KPC9zdmc+Cg==) no-repeat center/cover;
}


.header--black .menu__btn-icon {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeT0iNSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjIiIHJ4PSIxIiBmaWxsPSIjMDAwMDAwIi8+CjxyZWN0IHk9IjExIiB3aWR0aD0iMjQiIGhlaWdodD0iMiIgcng9IjEiIGZpbGw9IiMwMDAwMDAiLz4KPHJlY3QgeT0iMTciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyIiByeD0iMSIgZmlsbD0iIzAwMDAwMCIvPgo8L3N2Zz4K) no-repeat center/cover;
}


.menu__btn-mob {
  display: none;
}


.footer {
  background: #000000;
}


.footer .smallText {
  line-height: 18px;
}


.footer__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 120px;
  padding-bottom: 60px;
  max-width: 1340px;
}


.footer__logo {
  width: 135px;
  margin-bottom: 42px;
}


.footer__logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}


.footer__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 40px;
  margin-bottom: 163px;
}


.footer__col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}


.footer__col:nth-child(1) {
  max-width: 400px;
  width: 100%;
}


.footer__col:nth-child(2) {
  max-width: 290px;
  width: 100%;
}


.footer__col:nth-child(3) {
  max-width: 290px;
  width: 100%;
}


.footer__text {
  line-height: 19px;
}


.footer__btn {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-top: 40px;
}


.footer--ru {
  background: #fff;
}


.footer--ru .footer__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding-top: 53px;
  padding-bottom: 53px;
}


.footer--ru .footer__copy {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: "Gerbera";
}


.footer--ru .footer__info {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: unset;
  gap: unset;
  font-family: "Gerbera";
}


.footer--ru .footer__info a {
  color: #4F758B;
}


.page .first-screen {
  width: 100vw;
}


.page .first-screen__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100vh;
  min-height: 826px;
  padding: 80px;
}


.page .first-screen__section-2 {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


.page .first-screen__title {
  max-width: 1060px;
  margin-top: auto;
  margin-bottom: 57px;
}


.page .first-screen__link {
  -ms-flex-item-align: start;
      align-self: flex-start;
}


.page .first-screen__text {
  max-width: 616px;
  margin-top: 40px;
}


.page .our {
  background: #FBF9FA;
}


.page .our__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1336px;
  padding-top: 102px;
  padding-bottom: 160px;
  gap: 0 132px;
}


.page .our__col:nth-child(1) {
  width: 100%;
  max-width: 730px;
  margin-right: 20px;
}


.page .our__col:nth-child(1) h2 {
  display: none;
}


.page .our__col:nth-child(2) h2 {
  display: block;
}


.page .our__wrap {
  position: sticky;
  top: 110px;
}


.page .our__title {
  margin-top: 62px;
  margin-bottom: 8px;
}


.page .our__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  counter-reset: section;
}


.page .our__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 546px;
  padding: 60px;
  background: #F0F0EE;
  border-radius: 24px;
}


.page .our__item:before {
  content: counters(section, ".") " ";
  color: #A69F88;
  counter-increment: section;
}


.page .our__item:not(:last-child) {
  margin-bottom: 60px;
}


.page .our__name {
  margin-top: auto;
  margin-bottom: 40px;
  max-width: 320px;
}


.page .our__text {
  margin-bottom: 62px;
  max-width: 530px;
}


.page .our__btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin-top: 30px;
  text-align: center;
}


.page .staff {
  padding-top: 124px;
  padding-bottom: 160px;
  overflow: hidden;
  background: #707372;
}


.page .staff__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 120px;
}


.page .staff__container--1 {
  max-width: 675px;
  margin-bottom: 160px;
}


.page .staff__container--2 {
  margin-bottom: 0;
}


.page .staff__container--2 .staff__btn {
  display: none;
}


.page .staff__title {
  margin-top: 39px;
  margin-bottom: 37px;
  margin-left: -85px;
}


@supports (margin-left: clamp( -15rem , -26.0714285714rem  +  23.0654761905vw , -5.3125rem )) {
  .page .staff__title {
    margin-left: clamp( -15rem , -26.0714285714rem  +  23.0654761905vw , -5.3125rem );
  }
}


@supports not (margin-left: clamp( -15rem , -26.0714285714rem  +  23.0654761905vw , -5.3125rem )) {
  .page .staff__title {
    margin-left: calc(-15rem + 9.6875 * (100vw - 48rem) / 42);
  }
}


.page .staff__btn {
  -ms-flex-item-align: start;
      align-self: flex-start;
}


.page .staff__text {
  max-width: 620px;
  margin-bottom: 57px;
}


.page .staff__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 40px;
  overflow-x: auto;
}


.page .staff__item {
  width: 100%;
  max-width: 510px;
}


.page .staff__item-img {
  width: 100%;
  max-width: 510px;
  height: 340px;
  margin-bottom: 40px;
  overflow: hidden;
}


.page .staff__item-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}


.page .staff__item-title {
  margin-bottom: 20px;
}


.page .positions {
  background: #F0F0EE;
}


.page .positions__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 1340px;
  padding-top: 160px;
  padding-bottom: 160px;
}


.page .positions__title {
  margin-bottom: 40px;
}


.page .positions__text {
  max-width: 730px;
  margin-bottom: 56px;
}


.page .positions__btn {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-bottom: 120px;
}


.page-our-focus .screen__container {
  width: 100%;
  max-width: 1340px;
  height: 100vh;
  padding-top: 160px;
}


.page-our-focus .screen__container:nth-child(1) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


.page-our-focus .screen__title {
  max-width: 250px;
  margin-top: -128px;
}


.page-our-focus .screen__number {
  display: block;
  margin-bottom: 16px;
  color: #707372;
}


.page-our-focus .screen__name {
  margin-bottom: 40px;
}


.page-our-focus .screen__text {
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 24px;
}


.page-our-focus .screen__screen-list {
  margin-top: 40px;
}


.page-our-focus .screen-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}


.page-our-focus .screen-list__item {
  width: 100%;
  max-width: 130px;
}


.page-our-focus .screen-list__item:not(:last-child) {
  margin-right: 20px;
}


.page-our-focus .screen-list__number {
  display: block;
  margin-bottom: 20px;
}


.page-our-focus .screen-list__text {
  color: #707372;
}


.page-who-we-are {
  background: #707372;
}


.page-who-we-are .first-screen__container {
  max-width: 1340px;
  padding-top: 284px;
  padding-bottom: 160px;
}


.page-who-we-are .first-screen__title {
  margin-bottom: 40px;
}


.page-who-we-are .first-screen__text {
  max-width: 620px;
}


.page-who-we-are .first-screen__text:not(:last-child) {
  margin-bottom: 40px;
}


.page-who-we-are .team__container {
  max-width: 1340px;
  padding-top: 80px;
  padding-bottom: 160px;
}


.page-who-we-are .team__title {
  margin-bottom: 116px;
}


.page-who-we-are .team__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}


.page-who-we-are .team__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  gap: 130px;
}


.page-who-we-are .team__item:not(:last-child) {
  margin-bottom: 120px;
}


.page-who-we-are .team__col:nth-child(1) {
  width: 100%;
  max-width: 510px;
  margin-right: 20px;
}


.page-who-we-are .team__col:nth-child(2) {
  width: 100%;
  max-width: 620px;
}


.page-who-we-are .team__img {
  width: 100%;
  height: 420px;
}


.page-who-we-are .team__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}


.page-who-we-are .team__name {
  margin-bottom: 19px;
}


.page-who-we-are .team__position {
  display: block;
  margin-bottom: 40px;
}


.page-who-we-are .team__text {
  max-width: 590px;
  font-size: 18px;
  line-height: 24px;
}


.page-who-we-are .join__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 80px;
  padding-bottom: 160px;
}


.page-who-we-are .join__title {
  max-width: 960px;
  margin-right: auto;
  margin-bottom: 60px;
  margin-left: auto;
  text-align: center;
}


.positions-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 1280px;
}


.positions-list__item {
  border-radius: 24px;
}


.positions-list__item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  min-height: 164px;
  padding: 60px;
  background: #FBF9FA;
}


.positions-list__item:not(:last-child) {
  margin-bottom: 10px;
}


.positions-list__name {
  width: 100%;
  max-width: 670px;
  margin-right: 40px;
}


.positions-list__location {
  color: #A3A4A6;
  margin-right: 40px;
  width: 220px;
}


.positions-list__btn {
  margin-left: auto;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}


.portfolio__container {
  padding-top: 160px;
  padding-bottom: 160px;
  max-width: 1340px;
}


.portfolio__title {
  margin-bottom: 113px;
}


.portfolio__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 40px;
}


.portfolio__item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 620px;
  padding: 60px;
  background: #FBF9FA;
  border-radius: 24px;
}


.portfolio__tag {
  display: block;
  margin-bottom: 37px;
}


.portfolio__text {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  margin-bottom: 38px;
}


.portfolio__img {
  position: absolute;
  top: 60px;
  right: 60px;
  width: 40px;
  height: 40px;
}


.portfolio__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}


.portfolio__btn {
  -ms-flex-item-align: start;
      align-self: flex-start;
}


.page-careers {
  background: #F0F0EE;
}


.page-careers .first-screen__container {
  max-width: 1340px;
  padding-top: 284px;
  padding-bottom: 160px;
}


.page-careers .first-screen__title {
  margin-bottom: 40px;
}


.page-careers .first-screen__text {
  max-width: 730px;
}


.page-careers .first-screen__text:not(:last-child) {
  margin-bottom: 40px;
}


.page-careers .job__container {
  max-width: 1340px;
  padding-top: 80px;
  padding-bottom: 160px;
}


.page-careers .job__title {
  margin-bottom: 60px;
}


.page-careers .join__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 80px;
  padding-bottom: 160px;
}


.page-careers .join__title {
  max-width: 1240px;
  margin-right: auto;
  margin-bottom: 33px;
  margin-left: auto;
  text-align: center;
}


.page-careers .join__text {
  margin-bottom: 60px;
  max-width: 730px;
  text-align: center;
}


.page-ru .first-screen {
  position: relative;
  width: 100%;
  height: 100vh;
}


.page-ru .first-screen__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}


.page-ru .first-screen__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}


.page-ru .first-screen__bg video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0.6;
  -webkit-transform: scale(1.6);
      -ms-transform: scale(1.6);
          transform: scale(1.6);
}


.page-ru .first-screen__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  padding: 80px;
  min-height: 800px;
}


@supports (padding-right: clamp( 1.875rem , -49.8780487805rem  +  60.9756097561vw , 5rem )) {
  .page-ru .first-screen__container {
    padding-right: clamp( 1.875rem , -49.8780487805rem  +  60.9756097561vw , 5rem );
  }
}


@supports not (padding-right: clamp( 1.875rem , -49.8780487805rem  +  60.9756097561vw , 5rem )) {
  .page-ru .first-screen__container {
    padding-right: calc(1.875rem + 3.125 * (100vw - 84.875rem) / 5.125);
  }
}


@supports (padding-left: clamp( 1.875rem , -49.8780487805rem  +  60.9756097561vw , 5rem )) {
  .page-ru .first-screen__container {
    padding-left: clamp( 1.875rem , -49.8780487805rem  +  60.9756097561vw , 5rem );
  }
}


@supports not (padding-left: clamp( 1.875rem , -49.8780487805rem  +  60.9756097561vw , 5rem )) {
  .page-ru .first-screen__container {
    padding-left: calc(1.875rem + 3.125 * (100vw - 84.875rem) / 5.125);
  }
}


.page-ru .first-screen__title {
  margin-top: auto;
  max-width: 900px;
  margin-bottom: 40px;
}


.page-ru .first-screen__text {
  max-width: 789px;
  margin-bottom: 61px;
}


.page-ru .first-screen__link {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-bottom: 29px;
  padding: 25px 38px 30px;
}


.page-ru .our {
  background: #FBF9FA;
  width: 100%;
}


.page-ru .our__container {
  padding-top: 160px;
  padding-bottom: 166px;
  max-width: 1341px;
}


.page-ru .our__title {
  margin-bottom: 120px;
}


.page-ru .our__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 40px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}


.page-ru .our__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 400px;
  width: 100%;
}


.page-ru .our__img {
  width: 60px;
  height: 60px;
  margin-bottom: 40px;
}


.page-ru .our__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}


.page-ru .our__name {
  margin-bottom: 30px;
  max-width: 350px;
  line-height: 40px;
  height: 120px;
}


@supports (line-height: clamp( 2rem , 1.823943662rem  +  0.7511737089vw , 2.5rem )) {
  .page-ru .our__name {
    line-height: clamp( 2rem , 1.823943662rem  +  0.7511737089vw , 2.5rem );
  }
}


@supports not (line-height: clamp( 2rem , 1.823943662rem  +  0.7511737089vw , 2.5rem )) {
  .page-ru .our__name {
    line-height: calc(2rem + 0.5 * (100vw - 23.4375rem) / 66.5625);
  }
}


.page-ru .our__text {
  max-width: 390px;
}


.page-ru .staff {
  background: #7C878E;
  padding-top: 160px;
  padding-bottom: 154px;
}


.page-ru .staff__container {
  max-width: 1341px;
}


.page-ru .staff__title {
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}


.page-ru .staff__text {
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
}


.page-ru .staff__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 120px 40px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}


.page-ru .staff__item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 400px;
  width: 100%;
}


.page-ru .staff__list--two .staff__item {
  max-width: 620px;
}


.page-ru .staff__item-img {
  width: 100%;
  height: 340px;
  margin-bottom: 40px;
}


.page-ru .staff__item-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}


.page-ru .staff__list--two .staff__item-img {
  height: 380px;
}


.page-ru .staff__item-title {
  margin-bottom: 20px;
}


.page-ru .join {
  background: #F0F0EE;
  padding-top: 160px;
  padding-bottom: 160px;
}


.page-ru .join--vacancies .join__container {
  max-width: 1340px;
}


.page-ru .join--vacancies .join__text {
  margin-bottom: 40px;
}


.page-ru .join--vacancies .join__text--vacancies {
  margin-bottom: 58px;
}


.page-ru .join__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


.page-ru .join__title {
  margin-bottom: 40px;
  max-width: 870px;
  text-align: center;
}


.page-ru .join__title--left {
  margin-bottom: 120px;
  -ms-flex-item-align: start;
      align-self: flex-start;
  text-align: left;
}


.page-ru .join__text {
  margin-bottom: 60px;
  text-align: center;
  max-width: 620px;
}


.page-ru .join__btn {
  background: transparent;
}


.page-ru .join__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 160px;
}


.page-ru .join__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  max-width: 620px;
  width: 100%;
  background: #FBF9FA;
  border-radius: 24px;
  padding: 60px;
}


.page-ru .join__item .join__text {
  text-align: left;
}


.page-ru .join__city {
  display: block;
  margin-bottom: 10px;
}


.page-ru .join__name {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #000000;
}


.page-ru .join__text {
  margin-bottom: 61px;
  text-align: center;
}


.page-ru .join__btn {
  background: transparent !important;
  border-color: #000000 !important;
  color: #000000 !important;
}


.page-ru .join__btn:hover {
  color: #FBF9FA !important;
  background: #000000 !important;
  border-color: #fff !important;
}


.title,
.page .our__item:before {
  font-weight: 400;
  font-size: 100px;
  line-height: 1;
  letter-spacing: -0.02em;
}


.header--ru .title,
.header--ru .page .our__item:before,
.page .header--ru .our__item:before,
.footer--ru .title,
.footer--ru .page .our__item:before,
.page .footer--ru .our__item:before,
.page-ru .title,
.page-ru .page .our__item:before,
.page .page-ru .our__item:before {
  font-size: 80px;
  line-height: 1;
}


.title--white {
  color: #FBF9FA;
}


.title--black {
  color: #000000;
}


.smallTitle {
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: -0.02em;
}


.smallTitle--white {
  color: #FBF9FA;
}


.smallTitle--black {
  color: #000000;
}


.text {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}


.header--ru .text,
.footer--ru .text,
.page-ru .text {
  font-family: "Gerbera";
  font-size: 22px;
  line-height: 30px;
}


.text--white {
  color: #FBF9FA;
}


.text--black {
  color: #000000;
}


.header--ru .mediumText,
.footer--ru .mediumText,
.page-ru .mediumText {
  font-family: "Gerbera";
  font-size: 18px;
  line-height: 24px;
}


.mediumText--white {
  color: #FBF9FA;
}


.mediumText--black {
  color: #000000;
}


.smallText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}


.smallText--white {
  color: #FBF9FA;
}


.smallText--black {
  color: #000000;
}


.button {
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  border: 2px solid #FBF9FA;
  border-radius: 80px;
  padding: 29px 38px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #FBF9FA;
}


.button:hover {
  background: #FBF9FA;
  border: 2px solid #FBF9FA;
  color: #000000;
}


.header--black .button:hover {
  background: #000000;
  border: 2px solid #000000;
  color: #FBF9FA;
}


.header--ru .button,
.footer--ru .button,
.page-ru .button {
  border-radius: 12px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Gerbera";
  padding: 26px 38px 26px;
  color: #FBF9FA;
}


.header--ru .button:hover,
.footer--ru .button:hover,
.page-ru .button:hover {
  background: #000000;
  border: 2px solid #000000;
  color: #FBF9FA;
}


.header--black .button {
  color: #000000;
}


.header--ru .button--large,
.footer--ru .button--large,
.page-ru .button--large {
  font-size: 22px;
  line-height: 1;
}


.button--white {
  border: 2px solid #000000;
  color: #000000;
}


.header--ru .button--white,
.footer--ru .button--white,
.page-ru .button--white {
  background: #FBF9FA;
  color: #000000;
  border: 2px solid #FBF9FA;
}


.button--white:hover {
  background: #000000;
  border: 2px solid #000000;
  color: #FBF9FA;
}


.button--medium {
  font-size: 14px;
  line-height: 14px;
  padding: 11px 18px 10px;
  height: 40px;
}


.header--ru .button--medium,
.footer--ru .button--medium,
.page-ru .button--medium {
  padding: 10px 18px 10px;
}


.link {
  color: #4F758B;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}


.link:hover {
  text-decoration: underline;
}


@media (min-width: 768px) {
  .header--always-black .menu__link {
    color: #000000;
  }
}


@media (max-width: 1440px) {
@supports (padding-left: clamp( 1.25rem , 1.0299295775rem  +  0.9389671362vw , 1.875rem )) {
    [class*=__container] {
      padding-left: clamp( 1.25rem , 1.0299295775rem  +  0.9389671362vw , 1.875rem );
    }
}

@supports not (padding-left: clamp( 1.25rem , 1.0299295775rem  +  0.9389671362vw , 1.875rem )) {
    [class*=__container] {
      padding-left: calc(1.25rem + 0.625 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (padding-right: clamp( 1.25rem , 1.0299295775rem  +  0.9389671362vw , 1.875rem )) {
    [class*=__container] {
      padding-right: clamp( 1.25rem , 1.0299295775rem  +  0.9389671362vw , 1.875rem );
    }
}

@supports not (padding-right: clamp( 1.25rem , 1.0299295775rem  +  0.9389671362vw , 1.875rem )) {
    [class*=__container] {
      padding-right: calc(1.25rem + 0.625 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (width: clamp( 7.3125rem , 6.6742957746rem  +  2.7230046948vw , 9.125rem )) {
    .header--ru .logo__img,
    .footer--ru .logo__img,
    .page-ru .logo__img {
      width: clamp( 7.3125rem , 6.6742957746rem  +  2.7230046948vw , 9.125rem );
    }
}

@supports not (width: clamp( 7.3125rem , 6.6742957746rem  +  2.7230046948vw , 9.125rem )) {
    .header--ru .logo__img,
    .footer--ru .logo__img,
    .page-ru .logo__img {
      width: calc(7.3125rem + 1.8125 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (width: clamp( 6.75rem , 6.1558098592rem  +  2.5352112676vw , 8.4375rem )) {
    .logo__img {
      width: clamp( 6.75rem , 6.1558098592rem  +  2.5352112676vw , 8.4375rem );
    }
}

@supports not (width: clamp( 6.75rem , 6.1558098592rem  +  2.5352112676vw , 8.4375rem )) {
    .logo__img {
      width: calc(6.75rem + 1.6875 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (padding: clamp( 1.25rem , -0.0704225352rem  +  5.6338028169vw , 5rem )) {
    .page .first-screen__container {
      padding: clamp( 1.25rem , -0.0704225352rem  +  5.6338028169vw , 5rem );
    }
}

@supports not (padding: clamp( 1.25rem , -0.0704225352rem  +  5.6338028169vw , 5rem )) {
    .page .first-screen__container {
      padding: calc(1.25rem + 3.75 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (min-height: clamp( 46.875rem , 45.2024647887rem  +  7.1361502347vw , 51.625rem )) {
    .page .first-screen__container {
      min-height: clamp( 46.875rem , 45.2024647887rem  +  7.1361502347vw , 51.625rem );
    }
}

@supports not (min-height: clamp( 46.875rem , 45.2024647887rem  +  7.1361502347vw , 51.625rem )) {
    .page .first-screen__container {
      min-height: calc(46.875rem + 4.75 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (margin-top: clamp( -2.9375rem , -4.8521126761rem  +  8.1690140845vw , 2.5rem )) {
    .page .first-screen__text {
      margin-top: clamp( -2.9375rem , -4.8521126761rem  +  8.1690140845vw , 2.5rem );
    }
}

@supports not (margin-top: clamp( -2.9375rem , -4.8521126761rem  +  8.1690140845vw , 2.5rem )) {
    .page .first-screen__text {
      margin-top: calc(-2.9375rem + 5.4375 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (padding-top: clamp( 7.8125rem , 7.0422535211rem  +  3.2863849765vw , 10rem )) {
    .page-our-focus .screen__container {
      padding-top: clamp( 7.8125rem , 7.0422535211rem  +  3.2863849765vw , 10rem );
    }
}

@supports not (padding-top: clamp( 7.8125rem , 7.0422535211rem  +  3.2863849765vw , 10rem )) {
    .page-our-focus .screen__container {
      padding-top: calc(7.8125rem + 2.1875 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (max-width: clamp( 13.125rem , 12.2447183099rem  +  3.7558685446vw , 15.625rem )) {
    .page-our-focus .screen__title {
      max-width: clamp( 13.125rem , 12.2447183099rem  +  3.7558685446vw , 15.625rem );
    }
}

@supports not (max-width: clamp( 13.125rem , 12.2447183099rem  +  3.7558685446vw , 15.625rem )) {
    .page-our-focus .screen__title {
      max-width: calc(13.125rem + 2.5 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (margin-top: clamp( -9.375rem , -9.8591549296rem  +  2.0657276995vw , -8rem )) {
    .page-our-focus .screen__title {
      margin-top: clamp( -9.375rem , -9.8591549296rem  +  2.0657276995vw , -8rem );
    }
}

@supports not (margin-top: clamp( -9.375rem , -9.8591549296rem  +  2.0657276995vw , -8rem )) {
    .page-our-focus .screen__title {
      margin-top: calc(-9.375rem + 1.375 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (font-size: clamp( 2.5rem , 1.6197183099rem  +  3.7558685446vw , 5rem )) {
    .header--ru .title,
    .header--ru .page .our__item:before,
    .page .header--ru .our__item:before,
    .footer--ru .title,
    .footer--ru .page .our__item:before,
    .page .footer--ru .our__item:before,
    .page-ru .title,
    .page-ru .page .our__item:before,
    .page .page-ru .our__item:before {
      font-size: clamp( 2.5rem , 1.6197183099rem  +  3.7558685446vw , 5rem );
    }
}

@supports not (font-size: clamp( 2.5rem , 1.6197183099rem  +  3.7558685446vw , 5rem )) {
    .header--ru .title,
    .header--ru .page .our__item:before,
    .page .header--ru .our__item:before,
    .footer--ru .title,
    .footer--ru .page .our__item:before,
    .page .footer--ru .our__item:before,
    .page-ru .title,
    .page-ru .page .our__item:before,
    .page .page-ru .our__item:before {
      font-size: calc(2.5rem + 2.5 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (font-size: clamp( 3.375rem , 2.3626760563rem  +  4.3192488263vw , 6.25rem )) {
    .title,
    .page .our__item:before {
      font-size: clamp( 3.375rem , 2.3626760563rem  +  4.3192488263vw , 6.25rem );
    }
}

@supports not (font-size: clamp( 3.375rem , 2.3626760563rem  +  4.3192488263vw , 6.25rem )) {
    .title,
    .page .our__item:before {
      font-size: calc(3.375rem + 2.875 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (font-size: clamp( 1.875rem , 1.6549295775rem  +  0.9389671362vw , 2.5rem )) {
    .smallTitle {
      font-size: clamp( 1.875rem , 1.6549295775rem  +  0.9389671362vw , 2.5rem );
    }
}

@supports not (font-size: clamp( 1.875rem , 1.6549295775rem  +  0.9389671362vw , 2.5rem )) {
    .smallTitle {
      font-size: calc(1.875rem + 0.625 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (line-height: clamp( 2.25rem , 2.0299295775rem  +  0.9389671362vw , 2.875rem )) {
    .smallTitle {
      line-height: clamp( 2.25rem , 2.0299295775rem  +  0.9389671362vw , 2.875rem );
    }
}

@supports not (line-height: clamp( 2.25rem , 2.0299295775rem  +  0.9389671362vw , 2.875rem )) {
    .smallTitle {
      line-height: calc(2.25rem + 0.625 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (font-size: clamp( 1.125rem , 1.036971831rem  +  0.3755868545vw , 1.375rem )) {
    .header--ru .text,
    .footer--ru .text,
    .page-ru .text {
      font-size: clamp( 1.125rem , 1.036971831rem  +  0.3755868545vw , 1.375rem );
    }
}

@supports not (font-size: clamp( 1.125rem , 1.036971831rem  +  0.3755868545vw , 1.375rem )) {
    .header--ru .text,
    .footer--ru .text,
    .page-ru .text {
      font-size: calc(1.125rem + 0.25 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (line-height: clamp( 1.5rem , 1.3679577465rem  +  0.5633802817vw , 1.875rem )) {
    .header--ru .text,
    .footer--ru .text,
    .page-ru .text {
      line-height: clamp( 1.5rem , 1.3679577465rem  +  0.5633802817vw , 1.875rem );
    }
}

@supports not (line-height: clamp( 1.5rem , 1.3679577465rem  +  0.5633802817vw , 1.875rem )) {
    .header--ru .text,
    .footer--ru .text,
    .page-ru .text {
      line-height: calc(1.5rem + 0.375 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (font-size: clamp( 1.125rem , 0.9929577465rem  +  0.5633802817vw , 1.5rem )) {
    .text {
      font-size: clamp( 1.125rem , 0.9929577465rem  +  0.5633802817vw , 1.5rem );
    }
}

@supports not (font-size: clamp( 1.125rem , 0.9929577465rem  +  0.5633802817vw , 1.5rem )) {
    .text {
      font-size: calc(1.125rem + 0.375 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (line-height: clamp( 1.5rem , 1.3679577465rem  +  0.5633802817vw , 1.875rem )) {
    .text {
      line-height: clamp( 1.5rem , 1.3679577465rem  +  0.5633802817vw , 1.875rem );
    }
}

@supports not (line-height: clamp( 1.5rem , 1.3679577465rem  +  0.5633802817vw , 1.875rem )) {
    .text {
      line-height: calc(1.5rem + 0.375 * (100vw - 23.4375rem) / 66.5625);
    }
}

@supports (font-size: clamp( 1.125rem , 1.036971831rem  +  0.3755868545vw , 1.375rem )) {
    .header--ru .button--large,
    .footer--ru .button--large,
    .page-ru .button--large {
      font-size: clamp( 1.125rem , 1.036971831rem  +  0.3755868545vw , 1.375rem );
    }
}

@supports not (font-size: clamp( 1.125rem , 1.036971831rem  +  0.3755868545vw , 1.375rem )) {
    .header--ru .button--large,
    .footer--ru .button--large,
    .page-ru .button--large {
      font-size: calc(1.125rem + 0.25 * (100vw - 23.4375rem) / 66.5625);
    }
}
}


@media (max-width: 1357px) {
@supports (padding-right: clamp( 1.25rem , 1.0113289206rem  +  1.0183299389vw , 1.875rem )) {
    .page-ru .first-screen__container {
      padding-right: clamp( 1.25rem , 1.0113289206rem  +  1.0183299389vw , 1.875rem );
    }
}

@supports not (padding-right: clamp( 1.25rem , 1.0113289206rem  +  1.0183299389vw , 1.875rem )) {
    .page-ru .first-screen__container {
      padding-right: calc(1.25rem + 0.625 * (100vw - 23.4375rem) / 61.375);
    }
}

@supports (padding-left: clamp( 1.25rem , 1.0113289206rem  +  1.0183299389vw , 1.875rem )) {
    .page-ru .first-screen__container {
      padding-left: clamp( 1.25rem , 1.0113289206rem  +  1.0183299389vw , 1.875rem );
    }
}

@supports not (padding-left: clamp( 1.25rem , 1.0113289206rem  +  1.0183299389vw , 1.875rem )) {
    .page-ru .first-screen__container {
      padding-left: calc(1.25rem + 0.625 * (100vw - 23.4375rem) / 61.375);
    }
}
}


@media (max-width: 1080px) {
  .page-who-we-are .team__item {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}


@media (max-width: 1024px) {
@supports (margin-bottom: clamp( 2.5rem , -0.3890600924rem  +  12.3266563945vw , 7.5rem )) {
    .page-ru .join__title--left {
      margin-bottom: clamp( 2.5rem , -0.3890600924rem  +  12.3266563945vw , 7.5rem );
    }
}

@supports not (margin-bottom: clamp( 2.5rem , -0.3890600924rem  +  12.3266563945vw , 7.5rem )) {
    .page-ru .join__title--left {
      margin-bottom: calc(2.5rem + 5 * (100vw - 23.4375rem) / 40.5625);
    }
}

  .page-ru .join__list {
    -ms-flex-item-align: start;
        align-self: flex-start;
  }

@supports (gap: clamp( 1.25rem , 0.5277349769rem  +  3.0816640986vw , 2.5rem )) {
    .page-ru .join__list {
      gap: clamp( 1.25rem , 0.5277349769rem  +  3.0816640986vw , 2.5rem );
    }
}

@supports not (gap: clamp( 1.25rem , 0.5277349769rem  +  3.0816640986vw , 2.5rem )) {
    .page-ru .join__list {
      gap: calc(1.25rem + 1.25 * (100vw - 23.4375rem) / 40.5625);
    }
}

@supports (margin-bottom: clamp( 2.0625rem , 1.8097072419rem  +  1.0785824345vw , 2.5rem )) {
    .page-ru .join__name {
      margin-bottom: clamp( 2.0625rem , 1.8097072419rem  +  1.0785824345vw , 2.5rem );
    }
}

@supports not (margin-bottom: clamp( 2.0625rem , 1.8097072419rem  +  1.0785824345vw , 2.5rem )) {
    .page-ru .join__name {
      margin-bottom: calc(2.0625rem + 0.4375 * (100vw - 23.4375rem) / 40.5625);
    }
}
}


@media (max-width: 1020px) {
  .page-ru .our__list {
    row-gap: 120px;
  }

@supports (row-gap: clamp( 6.3125rem , 5.6220930233rem  +  2.9457364341vw , 7.5rem )) {
    .page-ru .our__list {
      row-gap: clamp( 6.3125rem , 5.6220930233rem  +  2.9457364341vw , 7.5rem );
    }
}

@supports not (row-gap: clamp( 6.3125rem , 5.6220930233rem  +  2.9457364341vw , 7.5rem )) {
    .page-ru .our__list {
      row-gap: calc(6.3125rem + 1.1875 * (100vw - 23.4375rem) / 40.3125);
    }
}

  .page-ru .staff__title {
    margin-left: 0;
  }

  .page-ru .staff__text {
    margin-left: 0;
  }
}


@media (max-width: 767px) {
  .header {
    height: 80px;
  }

  .header__container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .logo {
    z-index: 100;
  }

  .menu__wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-right: 0;
    background: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 99;
    padding: 60px 20px;
    -webkit-transform: translate(100%, 0);
        -ms-transform: translate(100%, 0);
            transform: translate(100%, 0);
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    overflow: auto;
  }

  .header._menu-open .menu__wrap {
    -webkit-transform: translate(0%, 0);
        -ms-transform: translate(0%, 0);
            transform: translate(0%, 0);
  }

  .header--ru .menu__wrap {
    background: #7C878E;
  }

  .menu__list {
    width: 100%;
    height: 100%;
    margin-right: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    z-index: 99;
    margin-top: 160px;
    margin-bottom: 20px;
  }

  .menu__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 60px;
  }

  .menu__link {
    font-weight: 400;
    font-size: 54px;
    line-height: 54px;
    letter-spacing: -0.02em;
  }

  .header--ru .menu__link {
    font-weight: 500;
    font-size: 40px !important;
    line-height: 40px !important;
    letter-spacing: -0.02em;
    color: #FBF9FA;
  }

  .menu__btn-icon {
    display: block;
    margin-left: 20px;
    width: 24px;
    height: 24px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeT0iNSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjIiIHJ4PSIxIiBmaWxsPSIjRkJGOUZBIi8+CjxyZWN0IHk9IjExIiB3aWR0aD0iMjQiIGhlaWdodD0iMiIgcng9IjEiIGZpbGw9IiNGQkY5RkEiLz4KPHJlY3QgeT0iMTciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyIiByeD0iMSIgZmlsbD0iI0ZCRjlGQSIvPgo8L3N2Zz4K) no-repeat center/cover;
    z-index: 100;
  }

  .menu__btn-mob {
    margin-top: auto;
    display: block;
    text-align: center;
    width: 100%;
    padding-top: 26px;
    padding-bottom: 26px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FBF9FA;
    border: 2px solid #FBF9FA;
    border-radius: 80px;
  }

  .footer__container {
    padding-top: 60px;
  }

@supports (padding-top: clamp( 2.875rem , 2.0379464286rem  +  3.5714285714vw , 3.75rem )) {
    .footer__container {
      padding-top: clamp( 2.875rem , 2.0379464286rem  +  3.5714285714vw , 3.75rem );
    }
}

@supports not (padding-top: clamp( 2.875rem , 2.0379464286rem  +  3.5714285714vw , 3.75rem )) {
    .footer__container {
      padding-top: calc(2.875rem + 0.875 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (padding-bottom: clamp( 1.25rem , -1.1415816327rem  +  10.2040816327vw , 3.75rem )) {
    .footer__container {
      padding-bottom: clamp( 1.25rem , -1.1415816327rem  +  10.2040816327vw , 3.75rem );
    }
}

@supports not (padding-bottom: clamp( 1.25rem , -1.1415816327rem  +  10.2040816327vw , 3.75rem )) {
    .footer__container {
      padding-bottom: calc(1.25rem + 2.5 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (width: clamp( 6.6875rem , 5.0133928571rem  +  7.1428571429vw , 8.4375rem )) {
    .footer__logo {
      width: clamp( 6.6875rem , 5.0133928571rem  +  7.1428571429vw , 8.4375rem );
    }
}

@supports not (width: clamp( 6.6875rem , 5.0133928571rem  +  7.1428571429vw , 8.4375rem )) {
    .footer__logo {
      width: calc(6.6875rem + 1.75 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (margin-bottom: clamp( 2.625rem , 3.3587372449rem  +  -1.5306122449vw , 3rem )) {
    .footer__logo {
      margin-bottom: clamp( 2.625rem , 3.3587372449rem  +  -1.5306122449vw , 3rem );
    }
}

@supports not (margin-bottom: clamp( 2.625rem , 3.3587372449rem  +  -1.5306122449vw , 3rem )) {
    .footer__logo {
      margin-bottom: calc(3rem + -0.375 * (100vw - 23.4375rem) / 24.5);
    }
}

  .footer__info {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

@supports (row-gap: clamp( 2.5rem , 12.6500318878rem  +  -21.1734693878vw , 7.6875rem )) {
    .footer__info {
      row-gap: clamp( 2.5rem , 12.6500318878rem  +  -21.1734693878vw , 7.6875rem );
    }
}

@supports not (row-gap: clamp( 2.5rem , 12.6500318878rem  +  -21.1734693878vw , 7.6875rem )) {
    .footer__info {
      row-gap: calc(7.6875rem + -5.1875 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports ((-moz-column-gap: clamp( 0.9375rem , -0.5572385204rem  +  6.3775510204vw , 2.5rem )) or (column-gap: clamp( 0.9375rem , -0.5572385204rem  +  6.3775510204vw , 2.5rem ))) {
    .footer__info {
      -webkit-column-gap: clamp( 0.9375rem , -0.5572385204rem  +  6.3775510204vw , 2.5rem );
         -moz-column-gap: clamp( 0.9375rem , -0.5572385204rem  +  6.3775510204vw , 2.5rem );
              column-gap: clamp( 0.9375rem , -0.5572385204rem  +  6.3775510204vw , 2.5rem );
    }
}

@supports not ((-moz-column-gap: clamp( 0.9375rem , -0.5572385204rem  +  6.3775510204vw , 2.5rem )) or (column-gap: clamp( 0.9375rem , -0.5572385204rem  +  6.3775510204vw , 2.5rem ))) {
    .footer__info {
      -webkit-column-gap: calc(0.9375rem + 1.5625 * (100vw - 23.4375rem) / 24.5);
         -moz-column-gap: calc(0.9375rem + 1.5625 * (100vw - 23.4375rem) / 24.5);
              column-gap: calc(0.9375rem + 1.5625 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (margin-bottom: clamp( 8.75rem , 7.3748405612rem  +  5.8673469388vw , 10.1875rem )) {
    .footer__info {
      margin-bottom: clamp( 8.75rem , 7.3748405612rem  +  5.8673469388vw , 10.1875rem );
    }
}

@supports not (margin-bottom: clamp( 8.75rem , 7.3748405612rem  +  5.8673469388vw , 10.1875rem )) {
    .footer__info {
      margin-bottom: calc(8.75rem + 1.4375 * (100vw - 23.4375rem) / 24.5);
    }
}

  .footer__col:nth-child(1) {
    max-width: 100%;
  }

  .footer__col:nth-child(2) {
    max-width: 165px;
  }

  .footer__col:nth-child(3) {
    max-width: 150px;
  }

  .footer--ru .footer__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 60px;
  }

  .footer--ru .footer__info {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }

  .page .first-screen__link {
    margin-bottom: 62px;
    width: 100%;
    text-align: center;
  }

  .page .our__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

@supports (padding-top: clamp( 3.8125rem , 1.3611288265rem  +  10.4591836735vw , 6.375rem )) {
    .page .our__container {
      padding-top: clamp( 3.8125rem , 1.3611288265rem  +  10.4591836735vw , 6.375rem );
    }
}

@supports not (padding-top: clamp( 3.8125rem , 1.3611288265rem  +  10.4591836735vw , 6.375rem )) {
    .page .our__container {
      padding-top: calc(3.8125rem + 2.5625 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (padding-bottom: clamp( 7.5625rem , 5.2307079082rem  +  9.9489795918vw , 10rem )) {
    .page .our__container {
      padding-bottom: clamp( 7.5625rem , 5.2307079082rem  +  9.9489795918vw , 10rem );
    }
}

@supports not (padding-bottom: clamp( 7.5625rem , 5.2307079082rem  +  9.9489795918vw , 10rem )) {
    .page .our__container {
      padding-bottom: calc(7.5625rem + 2.4375 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page .our__col:nth-child(1) h2 {
    display: block;
    margin-bottom: 60px;
  }

  .page .our__col:nth-child(2) h2 {
    display: none;
  }

  .page .our__title {
    display: none;
  }

  .page .our__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

@supports (padding: clamp( 2.5rem , 1.3042091837rem  +  5.1020408163vw , 3.75rem )) {
    .page .our__item {
      padding: clamp( 2.5rem , 1.3042091837rem  +  5.1020408163vw , 3.75rem );
    }
}

@supports not (padding: clamp( 2.5rem , 1.3042091837rem  +  5.1020408163vw , 3.75rem )) {
    .page .our__item {
      padding: calc(2.5rem + 1.25 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (height: clamp( 26.25rem , 36.0331632653rem  +  -20.4081632653vw , 31.25rem )) {
    .page .our__item {
      height: clamp( 26.25rem , 36.0331632653rem  +  -20.4081632653vw , 31.25rem );
    }
}

@supports not (height: clamp( 26.25rem , 36.0331632653rem  +  -20.4081632653vw , 31.25rem )) {
    .page .our__item {
      height: calc(31.25rem + -5 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (margin-bottom: clamp( 1.375rem , -0.897002551rem  +  9.693877551vw , 3.75rem )) {
    .page .our__item:not(:last-child) {
      margin-bottom: clamp( 1.375rem , -0.897002551rem  +  9.693877551vw , 3.75rem );
    }
}

@supports not (margin-bottom: clamp( 1.375rem , -0.897002551rem  +  9.693877551vw , 3.75rem )) {
    .page .our__item:not(:last-child) {
      margin-bottom: calc(1.375rem + 2.375 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (margin-bottom: clamp( 1.25rem , 0.0542091837rem  +  5.1020408163vw , 2.5rem )) {
    .page .our__name {
      margin-bottom: clamp( 1.25rem , 0.0542091837rem  +  5.1020408163vw , 2.5rem );
    }
}

@supports not (margin-bottom: clamp( 1.25rem , 0.0542091837rem  +  5.1020408163vw , 2.5rem )) {
    .page .our__name {
      margin-bottom: calc(1.25rem + 1.25 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (margin-bottom: clamp( 0.0000000625rem , -3.7069514083rem  +  15.8163262755vw , 3.875rem )) {
    .page .our__text {
      margin-bottom: clamp( 0.0000000625rem , -3.7069514083rem  +  15.8163262755vw , 3.875rem );
    }
}

@supports not (margin-bottom: clamp( 0.0000000625rem , -3.7069514083rem  +  15.8163262755vw , 3.875rem )) {
    .page .our__text {
      margin-bottom: calc(0.0000000625rem + 3.8749999375 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page .our__btn {
    width: 100%;
  }

@supports (margin-top: clamp( 1.875rem , 5.176817602rem  +  -6.887755102vw , 3.5625rem )) {
    .page .our__btn {
      margin-top: clamp( 1.875rem , 5.176817602rem  +  -6.887755102vw , 3.5625rem );
    }
}

@supports not (margin-top: clamp( 1.875rem , 5.176817602rem  +  -6.887755102vw , 3.5625rem )) {
    .page .our__btn {
      margin-top: calc(3.5625rem + -1.6875 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (padding-top: clamp( 5.1875rem , 2.7361288265rem  +  10.4591836735vw , 7.75rem )) {
    .page .staff {
      padding-top: clamp( 5.1875rem , 2.7361288265rem  +  10.4591836735vw , 7.75rem );
    }
}

@supports not (padding-top: clamp( 5.1875rem , 2.7361288265rem  +  10.4591836735vw , 7.75rem )) {
    .page .staff {
      padding-top: calc(5.1875rem + 2.5625 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page .staff {
      padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem );
    }
}

@supports not (padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page .staff {
      padding-bottom: calc(7.5rem + 2.5 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page .staff__container--1 {
    margin-bottom: 120px;
    max-width: 757px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .page .staff__container--1 .staff__btn {
    display: none;
  }

  .page .staff__container--2 {
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
  }

  .page .staff__container--2 .staff__btn {
    display: block;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    margin-top: 60px;
    width: 100%;
    text-align: center;
  }

  .page .staff__title {
    margin-left: 0;
  }

  .page .staff__text {
    margin-bottom: 0;
  }

  .page .staff__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

@supports (gap: clamp( 2.5rem , 9.837372449rem  +  -15.306122449vw , 6.25rem )) {
    .page .staff__list {
      gap: clamp( 2.5rem , 9.837372449rem  +  -15.306122449vw , 6.25rem );
    }
}

@supports not (gap: clamp( 2.5rem , 9.837372449rem  +  -15.306122449vw , 6.25rem )) {
    .page .staff__list {
      gap: calc(6.25rem + -3.75 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page .staff__item {
    max-width: unset;
  }

  .page .staff__item-img {
    max-width: unset;
    height: 440px;
  }

@supports (height: clamp( 17.5625rem , 8.0559630102rem  +  40.5612244898vw , 27.5rem )) {
    .page .staff__item-img {
      height: clamp( 17.5625rem , 8.0559630102rem  +  40.5612244898vw , 27.5rem );
    }
}

@supports not (height: clamp( 17.5625rem , 8.0559630102rem  +  40.5612244898vw , 27.5rem )) {
    .page .staff__item-img {
      height: calc(17.5625rem + 9.9375 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page .staff__item-position {
    font-size: 24px;
    line-height: 30px;
  }

@supports (padding-top: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page .positions__container {
      padding-top: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem );
    }
}

@supports not (padding-top: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page .positions__container {
      padding-top: calc(7.5rem + 2.5 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (padding-bottom: clamp( 0.0000000625rem , -9.5663264083rem  +  40.8163262755vw , 10rem )) {
    .page .positions__container {
      padding-bottom: clamp( 0.0000000625rem , -9.5663264083rem  +  40.8163262755vw , 10rem );
    }
}

@supports not (padding-bottom: clamp( 0.0000000625rem , -9.5663264083rem  +  40.8163262755vw , 10rem )) {
    .page .positions__container {
      padding-bottom: calc(0.0000000625rem + 9.9999999375 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (margin-bottom: clamp( 3.5rem , 10.9596619898rem  +  -15.5612244898vw , 7.3125rem )) {
    .page .positions__text {
      margin-bottom: clamp( 3.5rem , 10.9596619898rem  +  -15.5612244898vw , 7.3125rem );
    }
}

@supports not (margin-bottom: clamp( 3.5rem , 10.9596619898rem  +  -15.5612244898vw , 7.3125rem )) {
    .page .positions__text {
      margin-bottom: calc(7.3125rem + -3.8125 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page .positions__btn {
    margin-top: 60px;
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
    width: 100%;
    text-align: center;
  }

  .page .positions__list {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }

  .page-our-focus .screen__container:nth-child(4) {
    height: unset;
    min-height: 100vh;
  }

  .page-our-focus .screen-list {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 40px 15px;
  }

  .page-our-focus .screen-list__item {
    width: 47%;
    max-width: unset;
  }

  .page-our-focus .screen-list__item:not(:last-child) {
    margin-right: 0;
  }

@supports (padding-top: clamp( 12.6875rem , 7.8445471939rem  +  20.6632653061vw , 17.75rem )) {
    .page-who-we-are .first-screen__container {
      padding-top: clamp( 12.6875rem , 7.8445471939rem  +  20.6632653061vw , 17.75rem );
    }
}

@supports not (padding-top: clamp( 12.6875rem , 7.8445471939rem  +  20.6632653061vw , 17.75rem )) {
    .page-who-we-are .first-screen__container {
      padding-top: calc(12.6875rem + 5.0625 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (padding-bottom: clamp( 4.9375rem , 0.0945471939rem  +  20.6632653061vw , 10rem )) {
    .page-who-we-are .first-screen__container {
      padding-bottom: clamp( 4.9375rem , 0.0945471939rem  +  20.6632653061vw , 10rem );
    }
}

@supports not (padding-bottom: clamp( 4.9375rem , 0.0945471939rem  +  20.6632653061vw , 10rem )) {
    .page-who-we-are .first-screen__container {
      padding-bottom: calc(4.9375rem + 5.0625 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page-who-we-are .first-screen__container .text {
    font-size: 24px;
    line-height: 30px;
  }

@supports (padding-bottom: clamp( 5.125rem , 0.4614158163rem  +  19.8979591837vw , 10rem )) {
    .page-who-we-are .team__container {
      padding-bottom: clamp( 5.125rem , 0.4614158163rem  +  19.8979591837vw , 10rem );
    }
}

@supports not (padding-bottom: clamp( 5.125rem , 0.4614158163rem  +  19.8979591837vw , 10rem )) {
    .page-who-we-are .team__container {
      padding-bottom: calc(5.125rem + 4.875 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (margin-bottom: clamp( 6.0625rem , 4.9264987245rem  +  4.8469387755vw , 7.25rem )) {
    .page-who-we-are .team__title {
      margin-bottom: clamp( 6.0625rem , 4.9264987245rem  +  4.8469387755vw , 7.25rem );
    }
}

@supports not (margin-bottom: clamp( 6.0625rem , 4.9264987245rem  +  4.8469387755vw , 7.25rem )) {
    .page-who-we-are .team__title {
      margin-bottom: calc(6.0625rem + 1.1875 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page-who-we-are .team__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 40px;
  }

@supports (margin-bottom: clamp( 6.375rem , 5.2987882653rem  +  4.5918367347vw , 7.5rem )) {
    .page-who-we-are .team__item:not(:last-child) {
      margin-bottom: clamp( 6.375rem , 5.2987882653rem  +  4.5918367347vw , 7.5rem );
    }
}

@supports not (margin-bottom: clamp( 6.375rem , 5.2987882653rem  +  4.5918367347vw , 7.5rem )) {
    .page-who-we-are .team__item:not(:last-child) {
      margin-bottom: calc(6.375rem + 1.125 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page-who-we-are .team__col:nth-child(1) {
    margin-right: 0;
  }

@supports (height: clamp( 17.5rem , 9.1294642857rem  +  35.7142857143vw , 26.25rem )) {
    .page-who-we-are .team__img {
      height: clamp( 17.5rem , 9.1294642857rem  +  35.7142857143vw , 26.25rem );
    }
}

@supports not (height: clamp( 17.5rem , 9.1294642857rem  +  35.7142857143vw , 26.25rem )) {
    .page-who-we-are .team__img {
      height: calc(17.5rem + 8.75 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page-who-we-are .team__position {
    font-size: 24px;
    line-height: 30px;
  }

@supports (padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-who-we-are .join__container {
      padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem );
    }
}

@supports not (padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-who-we-are .join__container {
      padding-bottom: calc(7.5rem + 2.5 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page-who-we-are .join__btn {
    width: 100%;
    text-align: center;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .positions-list__item a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

@supports (padding: clamp( 2.5rem , 1.3042091837rem  +  5.1020408163vw , 3.75rem )) {
    .positions-list__item a {
      padding: clamp( 2.5rem , 1.3042091837rem  +  5.1020408163vw , 3.75rem );
    }
}

@supports not (padding: clamp( 2.5rem , 1.3042091837rem  +  5.1020408163vw , 3.75rem )) {
    .positions-list__item a {
      padding: calc(2.5rem + 1.25 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (margin-bottom: clamp( 0.625rem , 2.0924744898rem  +  -3.0612244898vw , 1.375rem )) {
    .positions-list__item:not(:last-child) {
      margin-bottom: clamp( 0.625rem , 2.0924744898rem  +  -3.0612244898vw , 1.375rem );
    }
}

@supports not (margin-bottom: clamp( 0.625rem , 2.0924744898rem  +  -3.0612244898vw , 1.375rem )) {
    .positions-list__item:not(:last-child) {
      margin-bottom: calc(1.375rem + -0.75 * (100vw - 23.4375rem) / 24.5);
    }
}

  .positions-list__name {
    margin-right: 0;
  }

  .positions-list__location {
    margin-right: 0;
    margin-bottom: 40px;
    width: 100%;
  }

  .positions-list__btn {
    margin-left: 0;
  }

@supports (margin-bottom: clamp( 6.0625rem , 5.1058673469rem  +  4.0816326531vw , 7.0625rem )) {
    .portfolio__title {
      margin-bottom: clamp( 6.0625rem , 5.1058673469rem  +  4.0816326531vw , 7.0625rem );
    }
}

@supports not (margin-bottom: clamp( 6.0625rem , 5.1058673469rem  +  4.0816326531vw , 7.0625rem )) {
    .portfolio__title {
      margin-bottom: calc(6.0625rem + 1 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (gap: clamp( 1.25rem , 0.0542091837rem  +  5.1020408163vw , 2.5rem )) {
    .portfolio__list {
      gap: clamp( 1.25rem , 0.0542091837rem  +  5.1020408163vw , 2.5rem );
    }
}

@supports not (gap: clamp( 1.25rem , 0.0542091837rem  +  5.1020408163vw , 2.5rem )) {
    .portfolio__list {
      gap: calc(1.25rem + 1.25 * (100vw - 23.4375rem) / 24.5);
    }
}

  .portfolio__item a {
    padding: 40px;
    height: unset;
    max-width: 100%;
  }

  .portfolio__name {
    margin-top: 62px;
  }

  .portfolio__img {
    right: unset;
    top: 40px;
    left: 40px;
  }

@supports (padding-top: clamp( 12.6875rem , 7.8445471939rem  +  20.6632653061vw , 17.75rem )) {
    .page-careers .first-screen__container {
      padding-top: clamp( 12.6875rem , 7.8445471939rem  +  20.6632653061vw , 17.75rem );
    }
}

@supports not (padding-top: clamp( 12.6875rem , 7.8445471939rem  +  20.6632653061vw , 17.75rem )) {
    .page-careers .first-screen__container {
      padding-top: calc(12.6875rem + 5.0625 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (padding-bottom: clamp( 4.9375rem , 0.0945471939rem  +  20.6632653061vw , 10rem )) {
    .page-careers .first-screen__container {
      padding-bottom: clamp( 4.9375rem , 0.0945471939rem  +  20.6632653061vw , 10rem );
    }
}

@supports not (padding-bottom: clamp( 4.9375rem , 0.0945471939rem  +  20.6632653061vw , 10rem )) {
    .page-careers .first-screen__container {
      padding-bottom: calc(4.9375rem + 5.0625 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page-careers .first-screen__text {
    font-size: 24px;
    line-height: 30px;
  }

@supports (padding-bottom: clamp( 4.9375rem , 0.0945471939rem  +  20.6632653061vw , 10rem )) {
    .page-careers .job__container {
      padding-bottom: clamp( 4.9375rem , 0.0945471939rem  +  20.6632653061vw , 10rem );
    }
}

@supports not (padding-bottom: clamp( 4.9375rem , 0.0945471939rem  +  20.6632653061vw , 10rem )) {
    .page-careers .job__container {
      padding-bottom: calc(4.9375rem + 5.0625 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-careers .join__container {
      padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem );
    }
}

@supports not (padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-careers .join__container {
      padding-bottom: calc(7.5rem + 2.5 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (margin-bottom: clamp( 2.0625rem , 2.6739477041rem  +  -1.2755102041vw , 2.375rem )) {
    .page-careers .join__title {
      margin-bottom: clamp( 2.0625rem , 2.6739477041rem  +  -1.2755102041vw , 2.375rem );
    }
}

@supports not (margin-bottom: clamp( 2.0625rem , 2.6739477041rem  +  -1.2755102041vw , 2.375rem )) {
    .page-careers .join__title {
      margin-bottom: calc(2.375rem + -0.3125 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page-careers .join__text {
    font-size: 24px;
    line-height: 30px;
  }

  .page-careers .join__btn {
    width: 100%;
    text-align: center;
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .page-ru .first-screen__bg video {
    display: none;
  }

@supports (padding-top: clamp( 1.375rem , -0.0092922607rem  +  5.9063136456vw , 5rem )) {
    .page-ru .first-screen__container {
      padding-top: clamp( 1.375rem , -0.0092922607rem  +  5.9063136456vw , 5rem );
    }
}

@supports not (padding-top: clamp( 1.375rem , -0.0092922607rem  +  5.9063136456vw , 5rem )) {
    .page-ru .first-screen__container {
      padding-top: calc(1.375rem + 3.625 * (100vw - 23.4375rem) / 61.375);
    }
}

@supports (padding-bottom: clamp( 1.375rem , -0.0092922607rem  +  5.9063136456vw , 5rem )) {
    .page-ru .first-screen__container {
      padding-bottom: clamp( 1.375rem , -0.0092922607rem  +  5.9063136456vw , 5rem );
    }
}

@supports not (padding-bottom: clamp( 1.375rem , -0.0092922607rem  +  5.9063136456vw , 5rem )) {
    .page-ru .first-screen__container {
      padding-bottom: calc(1.375rem + 3.625 * (100vw - 23.4375rem) / 61.375);
    }
}

  .page-ru .first-screen__link {
    width: 100%;
    text-align: center;
  }

@supports (margin-bottom: clamp( 1.8125rem , 8.2938456633rem  +  -13.5204081633vw , 5.125rem )) {
    .page-ru .first-screen__link {
      margin-bottom: clamp( 1.8125rem , 8.2938456633rem  +  -13.5204081633vw , 5.125rem );
    }
}

@supports not (margin-bottom: clamp( 1.8125rem , 8.2938456633rem  +  -13.5204081633vw , 5.125rem )) {
    .page-ru .first-screen__link {
      margin-bottom: calc(5.125rem + -3.3125 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (padding-top: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-ru .our__container {
      padding-top: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem );
    }
}

@supports not (padding-top: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-ru .our__container {
      padding-top: calc(7.5rem + 2.5 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (padding-bottom: clamp( 7.5rem , 4.7496811224rem  +  11.7346938776vw , 10.375rem )) {
    .page-ru .our__container {
      padding-bottom: clamp( 7.5rem , 4.7496811224rem  +  11.7346938776vw , 10.375rem );
    }
}

@supports not (padding-bottom: clamp( 7.5rem , 4.7496811224rem  +  11.7346938776vw , 10.375rem )) {
    .page-ru .our__container {
      padding-bottom: calc(7.5rem + 2.875 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (margin-bottom: clamp( 6.25rem , 5.0542091837rem  +  5.1020408163vw , 7.5rem )) {
    .page-ru .our__title {
      margin-bottom: clamp( 6.25rem , 5.0542091837rem  +  5.1020408163vw , 7.5rem );
    }
}

@supports not (margin-bottom: clamp( 6.25rem , 5.0542091837rem  +  5.1020408163vw , 7.5rem )) {
    .page-ru .our__title {
      margin-bottom: calc(6.25rem + 1.25 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page-ru .our__item {
    max-width: 100%;
  }

  .page-ru .our__name {
    height: unset;
    max-width: 100%;
  }

@supports (margin-bottom: clamp( 1.1875rem , -0.5048076923rem  +  2.6442307692vw , 1.875rem )) {
    .page-ru .our__name {
      margin-bottom: clamp( 1.1875rem , -0.5048076923rem  +  2.6442307692vw , 1.875rem );
    }
}

@supports not (margin-bottom: clamp( 1.1875rem , -0.5048076923rem  +  2.6442307692vw , 1.875rem )) {
    .page-ru .our__name {
      margin-bottom: calc(1.1875rem + 0.6875 * (100vw - 64rem) / 26);
    }
}

  .page-ru .our__text {
    max-width: 100%;
  }

@supports (padding-top: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-ru .staff {
      padding-top: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem );
    }
}

@supports not (padding-top: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-ru .staff {
      padding-top: calc(7.5rem + 2.5 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-ru .staff {
      padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem );
    }
}

@supports not (padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-ru .staff {
      padding-bottom: calc(7.5rem + 2.5 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (row-gap: clamp( 6.0625rem , 4.6873405612rem  +  5.8673469388vw , 7.5rem )) {
    .page-ru .staff__list {
      row-gap: clamp( 6.0625rem , 4.6873405612rem  +  5.8673469388vw , 7.5rem );
    }
}

@supports not (row-gap: clamp( 6.0625rem , 4.6873405612rem  +  5.8673469388vw , 7.5rem )) {
    .page-ru .staff__list {
      row-gap: calc(6.0625rem + 1.4375 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page-ru .staff__item {
    max-width: 100%;
  }

  .page-ru .staff__list--two .staff__item {
    max-width: 100%;
  }

@supports (height: clamp( 17.4375rem , -1.754942602rem  +  81.887755102vw , 37.5rem )) {
    .page-ru .staff__item-img {
      height: clamp( 17.4375rem , -1.754942602rem  +  81.887755102vw , 37.5rem );
    }
}

@supports not (height: clamp( 17.4375rem , -1.754942602rem  +  81.887755102vw , 37.5rem )) {
    .page-ru .staff__item-img {
      height: calc(17.4375rem + 20.0625 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (height: clamp( 17.4375rem , -1.754942602rem  +  81.887755102vw , 37.5rem )) {
    .page-ru .staff__list--two .staff__item-img {
      height: clamp( 17.4375rem , -1.754942602rem  +  81.887755102vw , 37.5rem );
    }
}

@supports not (height: clamp( 17.4375rem , -1.754942602rem  +  81.887755102vw , 37.5rem )) {
    .page-ru .staff__list--two .staff__item-img {
      height: calc(17.4375rem + 20.0625 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (padding-top: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-ru .join {
      padding-top: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem );
    }
}

@supports not (padding-top: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-ru .join {
      padding-top: calc(7.5rem + 2.5 * (100vw - 23.4375rem) / 24.5);
    }
}

@supports (padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-ru .join {
      padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem );
    }
}

@supports not (padding-bottom: clamp( 7.5rem , 5.1084183673rem  +  10.2040816327vw , 10rem )) {
    .page-ru .join {
      padding-bottom: calc(7.5rem + 2.5 * (100vw - 23.4375rem) / 24.5);
    }
}

  .page-ru .join__item {
    max-width: 100%;
  }

@supports (padding: clamp( 1.875rem , 3.0978954082rem  +  -2.5510204082vw , 2.5rem )) {
    .page-ru .join__item {
      padding: clamp( 1.875rem , 3.0978954082rem  +  -2.5510204082vw , 2.5rem );
    }
}

@supports not (padding: clamp( 1.875rem , 3.0978954082rem  +  -2.5510204082vw , 2.5rem )) {
    .page-ru .join__item {
      padding: calc(2.5rem + -0.625 * (100vw - 23.4375rem) / 24.5);
    }
}
}


@media (max-width: 763px) {
  .page .our__col:nth-child(1) {
    margin-right: 0;
  }
}


@media (min-width: 768px) and (max-width: 1440px) {
@supports ((-moz-column-gap: clamp( 5rem , 1.2857142857rem  +  7.7380952381vw , 8.25rem )) or (column-gap: clamp( 5rem , 1.2857142857rem  +  7.7380952381vw , 8.25rem ))) {
    .page .our__container {
      -webkit-column-gap: clamp( 5rem , 1.2857142857rem  +  7.7380952381vw , 8.25rem );
         -moz-column-gap: clamp( 5rem , 1.2857142857rem  +  7.7380952381vw , 8.25rem );
              column-gap: clamp( 5rem , 1.2857142857rem  +  7.7380952381vw , 8.25rem );
    }
}

@supports not ((-moz-column-gap: clamp( 5rem , 1.2857142857rem  +  7.7380952381vw , 8.25rem )) or (column-gap: clamp( 5rem , 1.2857142857rem  +  7.7380952381vw , 8.25rem ))) {
    .page .our__container {
      -webkit-column-gap: calc(5rem + 3.25 * (100vw - 48rem) / 42);
         -moz-column-gap: calc(5rem + 3.25 * (100vw - 48rem) / 42);
              column-gap: calc(5rem + 3.25 * (100vw - 48rem) / 42);
    }
}

@supports (max-width: clamp( 20.625rem , -7.9464285714rem  +  59.5238095238vw , 45.625rem )) {
    .page .our__col:nth-child(1) {
      max-width: clamp( 20.625rem , -7.9464285714rem  +  59.5238095238vw , 45.625rem );
    }
}

@supports not (max-width: clamp( 20.625rem , -7.9464285714rem  +  59.5238095238vw , 45.625rem )) {
    .page .our__col:nth-child(1) {
      max-width: calc(20.625rem + 25 * (100vw - 48rem) / 42);
    }
}

@supports (padding: clamp( 2.5rem , 1.0714285714rem  +  2.9761904762vw , 3.75rem )) {
    .page .our__item {
      padding: clamp( 2.5rem , 1.0714285714rem  +  2.9761904762vw , 3.75rem );
    }
}

@supports not (padding: clamp( 2.5rem , 1.0714285714rem  +  2.9761904762vw , 3.75rem )) {
    .page .our__item {
      padding: calc(2.5rem + 1.25 * (100vw - 48rem) / 42);
    }
}

@supports (height: clamp( 26.25rem , 17.25rem  +  18.75vw , 34.125rem )) {
    .page .our__item {
      height: clamp( 26.25rem , 17.25rem  +  18.75vw , 34.125rem );
    }
}

@supports not (height: clamp( 26.25rem , 17.25rem  +  18.75vw , 34.125rem )) {
    .page .our__item {
      height: calc(26.25rem + 7.875 * (100vw - 48rem) / 42);
    }
}

@supports (max-width: clamp( 20rem , 6.4285714286rem  +  28.2738095238vw , 31.875rem )) {
    .page .staff__item {
      max-width: clamp( 20rem , 6.4285714286rem  +  28.2738095238vw , 31.875rem );
    }
}

@supports not (max-width: clamp( 20rem , 6.4285714286rem  +  28.2738095238vw , 31.875rem )) {
    .page .staff__item {
      max-width: calc(20rem + 11.875 * (100vw - 48rem) / 42);
    }
}

@supports (max-width: clamp( 20rem , 6.4285714286rem  +  28.2738095238vw , 31.875rem )) {
    .page .staff__item-img {
      max-width: clamp( 20rem , 6.4285714286rem  +  28.2738095238vw , 31.875rem );
    }
}

@supports not (max-width: clamp( 20rem , 6.4285714286rem  +  28.2738095238vw , 31.875rem )) {
    .page .staff__item-img {
      max-width: calc(20rem + 11.875 * (100vw - 48rem) / 42);
    }
}

@supports (gap: clamp( 2.5rem , -3.9285714286rem  +  13.3928571429vw , 8.125rem )) {
    .page-who-we-are .team__item {
      gap: clamp( 2.5rem , -3.9285714286rem  +  13.3928571429vw , 8.125rem );
    }
}

@supports not (gap: clamp( 2.5rem , -3.9285714286rem  +  13.3928571429vw , 8.125rem )) {
    .page-who-we-are .team__item {
      gap: calc(2.5rem + 5.625 * (100vw - 48rem) / 42);
    }
}

@supports (max-width: clamp( 20.625rem , -0.0892857143rem  +  43.1547619048vw , 38.75rem )) {
    .portfolio__item a {
      max-width: clamp( 20.625rem , -0.0892857143rem  +  43.1547619048vw , 38.75rem );
    }
}

@supports not (max-width: clamp( 20.625rem , -0.0892857143rem  +  43.1547619048vw , 38.75rem )) {
    .portfolio__item a {
      max-width: calc(20.625rem + 18.125 * (100vw - 48rem) / 42);
    }
}

@supports (padding: clamp( 2.5rem , 1.0714285714rem  +  2.9761904762vw , 3.75rem )) {
    .portfolio__item a {
      padding: clamp( 2.5rem , 1.0714285714rem  +  2.9761904762vw , 3.75rem );
    }
}

@supports not (padding: clamp( 2.5rem , 1.0714285714rem  +  2.9761904762vw , 3.75rem )) {
    .portfolio__item a {
      padding: calc(2.5rem + 1.25 * (100vw - 48rem) / 42);
    }
}

@supports (top: clamp( 2.5625rem , 1.2053571429rem  +  2.8273809524vw , 3.75rem )) {
    .portfolio__img {
      top: clamp( 2.5625rem , 1.2053571429rem  +  2.8273809524vw , 3.75rem );
    }
}

@supports not (top: clamp( 2.5625rem , 1.2053571429rem  +  2.8273809524vw , 3.75rem )) {
    .portfolio__img {
      top: calc(2.5625rem + 1.1875 * (100vw - 48rem) / 42);
    }
}

@supports (right: clamp( 2.5625rem , 1.2053571429rem  +  2.8273809524vw , 3.75rem )) {
    .portfolio__img {
      right: clamp( 2.5625rem , 1.2053571429rem  +  2.8273809524vw , 3.75rem );
    }
}

@supports not (right: clamp( 2.5625rem , 1.2053571429rem  +  2.8273809524vw , 3.75rem )) {
    .portfolio__img {
      right: calc(2.5625rem + 1.1875 * (100vw - 48rem) / 42);
    }
}

@supports (max-width: clamp( 15.6875rem , 5.0446428571rem  +  22.1726190476vw , 25rem )) {
    .page-ru .staff__item {
      max-width: clamp( 15.6875rem , 5.0446428571rem  +  22.1726190476vw , 25rem );
    }
}

@supports not (max-width: clamp( 15.6875rem , 5.0446428571rem  +  22.1726190476vw , 25rem )) {
    .page-ru .staff__item {
      max-width: calc(15.6875rem + 9.3125 * (100vw - 48rem) / 42);
    }
}

@supports (max-width: clamp( 20.625rem , -0.0892857143rem  +  43.1547619048vw , 38.75rem )) {
    .page-ru .staff__list--two .staff__item {
      max-width: clamp( 20.625rem , -0.0892857143rem  +  43.1547619048vw , 38.75rem );
    }
}

@supports not (max-width: clamp( 20.625rem , -0.0892857143rem  +  43.1547619048vw , 38.75rem )) {
    .page-ru .staff__list--two .staff__item {
      max-width: calc(20.625rem + 18.125 * (100vw - 48rem) / 42);
    }
}

@supports (height: clamp( 15.625rem , 9.1964285714rem  +  13.3928571429vw , 21.25rem )) {
    .page-ru .staff__item-img {
      height: clamp( 15.625rem , 9.1964285714rem  +  13.3928571429vw , 21.25rem );
    }
}

@supports not (height: clamp( 15.625rem , 9.1964285714rem  +  13.3928571429vw , 21.25rem )) {
    .page-ru .staff__item-img {
      height: calc(15.625rem + 5.625 * (100vw - 48rem) / 42);
    }
}
}


@media (min-width: 1024px) and (max-width: 1440px) {
@supports (max-width: clamp( 18.5rem , 2.5rem  +  25vw , 25rem )) {
    .page-ru .our__item {
      max-width: clamp( 18.5rem , 2.5rem  +  25vw , 25rem );
    }
}

@supports not (max-width: clamp( 18.5rem , 2.5rem  +  25vw , 25rem )) {
    .page-ru .our__item {
      max-width: calc(18.5rem + 6.5 * (100vw - 64rem) / 26);
    }
}

@supports (max-width: clamp( 28.5625rem , 3.4855769231rem  +  39.1826923077vw , 38.75rem )) {
    .page-ru .join__item {
      max-width: clamp( 28.5625rem , 3.4855769231rem  +  39.1826923077vw , 38.75rem );
    }
}

@supports not (max-width: clamp( 28.5625rem , 3.4855769231rem  +  39.1826923077vw , 38.75rem )) {
    .page-ru .join__item {
      max-width: calc(28.5625rem + 10.1875 * (100vw - 64rem) / 26);
    }
}

@supports (padding: clamp( 2.8125rem , 0.5048076923rem  +  3.6057692308vw , 3.75rem )) {
    .page-ru .join__item {
      padding: clamp( 2.8125rem , 0.5048076923rem  +  3.6057692308vw , 3.75rem );
    }
}

@supports not (padding: clamp( 2.8125rem , 0.5048076923rem  +  3.6057692308vw , 3.75rem )) {
    .page-ru .join__item {
      padding: calc(2.8125rem + 0.9375 * (100vw - 64rem) / 26);
    }
}
}


@media (min-width: 768px) and (max-width: 1024px) {
@supports (max-width: clamp( 18.75rem , 0.0000000625rem  +  39.0625vw , 25rem )) {
    .page-ru .our__item {
      max-width: clamp( 18.75rem , 0.0000000625rem  +  39.0625vw , 25rem );
    }
}

@supports not (max-width: clamp( 18.75rem , 0.0000000625rem  +  39.0625vw , 25rem )) {
    .page-ru .our__item {
      max-width: calc(18.75rem + 6.25 * (100vw - 48rem) / 16);
    }
}

@supports (height: clamp( 18.75rem , 3.75rem  +  31.25vw , 23.75rem )) {
    .page-ru .staff__list--two .staff__item-img {
      height: clamp( 18.75rem , 3.75rem  +  31.25vw , 23.75rem );
    }
}

@supports not (height: clamp( 18.75rem , 3.75rem  +  31.25vw , 23.75rem )) {
    .page-ru .staff__list--two .staff__item-img {
      height: calc(18.75rem + 5 * (100vw - 48rem) / 16);
    }
}
}


@media (min-width: 768px) and (max-width: 1023px) {
@supports (max-width: clamp( 20.625rem , -3.2808823529rem  +  49.8039215686vw , 28.5625rem )) {
    .page-ru .join__item {
      max-width: clamp( 20.625rem , -3.2808823529rem  +  49.8039215686vw , 28.5625rem );
    }
}

@supports not (max-width: clamp( 20.625rem , -3.2808823529rem  +  49.8039215686vw , 28.5625rem )) {
    .page-ru .join__item {
      max-width: calc(20.625rem + 7.9375 * (100vw - 48rem) / 15.9375);
    }
}

@supports (padding: clamp( 1.875rem , -0.9485294118rem  +  5.8823529412vw , 2.8125rem )) {
    .page-ru .join__item {
      padding: clamp( 1.875rem , -0.9485294118rem  +  5.8823529412vw , 2.8125rem );
    }
}

@supports not (padding: clamp( 1.875rem , -0.9485294118rem  +  5.8823529412vw , 2.8125rem )) {
    .page-ru .join__item {
      padding: calc(1.875rem + 0.9375 * (100vw - 48rem) / 15.9375);
    }
}
}


@media (max-height: 720px) {
  .page-ru .first-screen {
    height: 100%;
  }
}